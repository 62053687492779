"use client";
import { parseEther } from "@ethersproject/units";
import { Seaport } from "@opensea/seaport-js";
import { ItemType } from "@opensea/seaport-js/lib/constants";
import { initializeSeaport } from "./initialize";
import { addListingToDB } from "@/server/actions/nft/listings/addListingToDB";

export const createSeaportListing = async (
  provider,
  sellerAddress: string,
  tokenNetwork: string,
  contractAddress: string,
  tokenId: string,
  tokenType: string,
  price: string,
  cryptoCurrency: string // e.g., 'ETH'
) => {
  if (!provider.getSigner) {
    console.error("Invalid provider: getSigner method not found.");
    return;
  }
  // @ts-ignore
  const seaport: Seaport = initializeSeaport(provider);

  const itemType = tokenType.includes("721")
    ? ItemType.ERC721
    : tokenType.includes("1155")
    ? ItemType.ERC1155
    : ItemType.ERC721;

  const priceInCryptoUnits = parseEther(price).toString();

  const start = "0";
  const end = Number.MAX_SAFE_INTEGER.toString();

  const { executeAllActions } = await seaport.createOrder(
    {
      // NFT being sold
      offer: [
        {
          itemType: itemType,
          token: contractAddress,
          identifier: tokenId,
          amount: "1", // TO DO: make quantity to be sold dynamic
        },
      ],
      // Payment for the NFT
      consideration: [
        {
          amount: priceInCryptoUnits,
          recipient: sellerAddress,
          // token: currencyAddresses[0].address,
        },
      ],
      allowPartialFills: false,
      startTime: start,
      endTime: end,
    },
    sellerAddress
  );
  try {
    const transactionReceipt = await executeAllActions();
    console.log("Transaction receipt:", transactionReceipt);
    const order_hash = await seaport.getOrderHash(
      transactionReceipt.parameters
    );

    const seller = transactionReceipt.parameters.offerer;

    // Convert cryptoCurrency price to float
    const cryptoAmount = parseFloat(price);

    // Save to Database Listings table
    await addListingToDB(
      order_hash,
      transactionReceipt,
      transactionReceipt.signature,
      cryptoAmount,
      cryptoCurrency,
      transactionReceipt.parameters.endTime,
      tokenNetwork,
      contractAddress,
      tokenId,
      seller
    );

    console.log("Order hash:", order_hash);
    return transactionReceipt;
  } catch (error) {
    console.error("Transaction failed:", error);
    // throw error;
  }
};
